import { graphql } from 'gatsby'
import { useState } from 'react'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import PageSectionBannerTextBackground from '../components/PageSectionBannerTextBackground'
import SampleExampleBanner from '../components/SampleExampleBanner'
import SampleExampleCopyContent from '../components/SampleExampleCopyContent'
import SampleExampleStickyButton from '../components/SampleExampleStickyButton'
import Section from '../components/Section'
import useSignUpLink from '../hooks/queries/useSignUpLink'
import { t as tBuilder } from '../IntlContextProvider'
import { useIntl } from '../utils/IntlContext'
import { getLocalizedRootUrlPrefix } from '../utils/locales'

export const query = graphql`
  query CVExample($contentful_id: String!, $locale: String!) {
    contentfulCvExampleCategory(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      parentPage {
        slug
      }
      slug
      node_locale
      name
      h1Title
      metaTitle
      metaDescription
      exampleSummary: childContentfulCvExampleCategorySummaryTextNode {
        childMarkdownRemark {
          htmlAst
          tableOfContents(maxDepth: 3)
          timeToRead
          headings {
            depth
          }
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
      cv_examples {
        id
        title
        thumbnail: cv_image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 297)
            }
          }
        }
        image: cv_image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 50)
            }
          }
        }
      }
    }
    sectionBackgroundImage: contentfulAsset(
      contentful_id: { eq: "3uHB83lObWWKGdjfkoz3Nl" }
      node_locale: { eq: "en-US" }
    ) {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    shapesCvBanner: contentfulBannerTextBackground(
      contentful_id: { eq: "1Sh1B0aN8HLsiJlcYU4rkP" }
      node_locale: { eq: $locale }
    ) {
      ...BannerTextBackground
    }
    microcopy: allContentfulMicrocopy(
      filter: { key: { in: ["example_meta_title", "example_meta_description"] }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`

const CvExample = ({
  pageContext: { translatedPages },
  data: {
    contentfulCvExampleCategory: { parentPage, slug, name, h1Title, cv_examples, exampleSummary },
    shapesCvBanner,
  },
}) => {
  const [activeCvIndex, setActiveCvIndex] = useState(0)

  const { locale, t } = useIntl()

  const parentBasePath = getLocalizedRootUrlPrefix(parentPage, locale)

  const signupLink = useSignUpLink(locale)

  const displayTOC = exampleSummary?.childMarkdownRemark?.headings.filter((heading) => heading.depth <= 3).length >= 3
  const displayStickyResume = exampleSummary?.childMarkdownRemark?.timeToRead >= 2 && displayTOC

  const formattedCVs = cv_examples.map((example) => ({
    ...example,
    image: example.image.localFile,
    thumbnail: example.thumbnail.localFile,
  }))

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <SampleExampleBanner
        resumes={formattedCVs}
        activeIndex={activeCvIndex}
        setActiveIndex={setActiveCvIndex}
        signupLink={signupLink}
        name={name}
        h1Title={h1Title}
        parentBasePath={parentBasePath}
        slug={slug}
      />
      <SampleExampleCopyContent
        howTo={exampleSummary}
        signupLink={signupLink}
        tableOfContents={exampleSummary?.childMarkdownRemark?.tableOfContents}
        displayTOC={displayTOC}
        displayStickyResume={displayStickyResume}
        highlightedResume={formattedCVs[activeCvIndex]}
      />
      <Section
        align="center"
        hasDescription
        hasComponent>
        <PageSectionBannerTextBackground
          altCta={signupLink}
          altTitle={t('next_job_faster')}
          banner={shapesCvBanner}
        />
      </Section>
      <SampleExampleStickyButton
        highlightedResume={formattedCVs[activeCvIndex]}
        signupLink={signupLink}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  location: { pathname },
  pageContext: {
    canonicalUrl,
    intl: { locale },
    translatedPages,
  },
  data: {
    contentfulCvExampleCategory: { name, metaTitle, metaDescription },
    microcopy,
  },
}) => {
  const translationDictionary = Object.assign(
    {},
    ...microcopy.edges.map((edge) => ({ [edge.node.key]: edge.node.value })),
  )

  const t = tBuilder(translationDictionary, locale, pathname)

  metaTitle = metaTitle || t('example_meta_title', { occupation: name })
  metaDescription = metaDescription || t('example_meta_description', { occupation: name })

  return (
    <GatsbyHead
      pageTitle={metaTitle}
      metaDescription={metaDescription}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default CvExample
